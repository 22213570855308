import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Bep from '../views/Bep'
import Eth from '../views/Eth'
import Index from '../views/Index'
import Home from '../views/Home'

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/trc',
    component: Index
  },
  {
    path: '/erc',
    component: Eth
  },
  {
    path: '/bsc',
    component: Bep
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
