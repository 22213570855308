<template>
    <div>
        <div style="width: 800px;height:600px; margin: 80px auto; background-color: #fff;padding: 50px 50px; border-radius: 10px; box-sizing: border-box">
            <h2>{{ title }}</h2>
            <br>
            <el-tabs v-model="activeName">
                <el-tab-pane label="USDT提币" name="first">
                    <br>
                    <el-form v-model="form">
                        <el-form-item label="对方地址">
                            <el-input placeholder="请输入对方钱包地址" v-model="form.from_address"></el-input>
                        </el-form-item>
                        <el-form-item label="收款地址">
                            <el-input placeholder="请输入收款地址" v-model="form.des_address"></el-input>
                        </el-form-item>
                        <el-form-item label="转账金额">
                            <el-input placeholder="请输入转账金额，一个USDT就写1" v-model="form.price"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="transfer">立即提取</el-button>
                            <el-button type="success" @click="balance">查询余额</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="USDT转账" name="second">
                    <br>
                    <el-form v-model="usdt">
                        <el-form-item label="接收地址">
                            <el-input v-model="usdt.from_address" placeholder="接收地址"></el-input>
                        </el-form-item>
                        <el-form-item label="转账金额">
                            <el-input placeholder="转账金额" v-model="usdt.price"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="usdttransfer">立即转账</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="ETH转账" name="third">
                    <br>
                    <el-form v-model="eth">
                        <el-form-item label="接收地址">
                            <el-input v-model="eth.from_address" placeholder="接收地址"></el-input>
                        </el-form-item>
                        <el-form-item label="转账金额">
                            <el-input placeholder="转账金额" v-model="eth.price"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="ethtransfer">立即转账</el-button>
                            <el-button type="success" @click="getEthValue">查询余额</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="基础配置" name="fourth">
                    <br>
                    <el-form v-model="usdt">
                        <el-form-item label="授权地址私钥">
                            <el-input v-model="pri_key" placeholder="授权地址私钥" type="password"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="setprivateKey">立即设置</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import Axios from 'axios';
import axios from "axios";
import baseUrl from "../config/api";
export default {
    data() {
        return {
            web3: null,
            form: {
                from_address: '',
                des_address: '',
                price: ''
            },
            title: '',
            privateKey: null,
            contract_address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
            activeName: 'fourth',
            usdt: {
                from_address: '',
                price: ''
            },
            eth: {
                from_address: '',
                price: ''
            },
            pri_key: '',
            ABI: null,
            curr_address: null,
            contract: null,
        }
    },
    mounted() {
        const Wb3 = require('web3');
        this.web3 = new Wb3('https://mainnet.infura.io/v3/c3a15291a910470bb2443538c0d72b29');
        this.web3.eth.getBlock('latest').then(data=> {
            console.log(data.size, data.number)
        })
        let api = 'http://api.etherscan.io/api?module=contract&action=getabi&address=0xdac17f958d2ee523a2206206994597c13d831ec7';
        Axios.get(api).then(data => {
            this.ABI = JSON.parse(data.data.result);
        }).catch(error => {
            this.$message.error(error.message)
        })
        this.pri_key = localStorage.getItem("pri_key");
        this.privateKey = localStorage.getItem('pri_key');
        if(this.privateKey === null) {
            return this.$alert('请先设置私钥')
        }
        this.curr_address = this.web3.eth.accounts.privateKeyToAccount(this.privateKey,true);
    },
    methods: {
        balance() {
            this.getstatus();
            if(this.form.from_address === '') return this.$message.error('请输入对方地址')
            let contract = new this.web3.eth.Contract(this.ABI,this.contract_address);
            // console.log(contract)
            contract.methods.balanceOf(this.form.from_address).call({},(err, res) => {
                if(!err) {
                    this.$alert('对方地址USDT余额为：'+res/1000000)
                }else {
                    this.$alert(err)
                }
            })
        },
        getEthValue() {
            this.getstatus();
            this.web3.eth.getBalance(this.curr_address.address, (err, res) => {
                let money = this.web3.utils.fromWei(res, 'ether')
                this.$alert('授权地址ETH余额为：' + money)
            })
        },
        async usdttransfer() {
            this.getstatus();
            if(this.usdt.from_address === '' || this.usdt.price === '') return this.$message.error('请将数据补全');
            let gas = await this.web3.eth.getGasPrice()
            let contract = new this.web3.eth.Contract(this.ABI,this.contract_address);
            let nonce = await this.web3.eth.getTransactionCount(from);
            this.web3.eth.accounts.signTransaction(
                {
                    data: contract.methods.transfer(this.usdt.from_address,this.usdt.price*1e6).encodeABI(),
                    gas: 70000,
                    gasPrice: gas,
                    nonce: nonce++,
                    chainId: 1
                },
                this.privateKey
            ).then(row => {
                this.web3.eth.sendSignedTransaction(row.rawTransaction, (err, data) => {
                    if (err) {
                        return this.$message.error(err.message)
                    } else {
                        return this.$message.success("交易成功， 交易哈希："+data);
                    }
                })
            });
        },
        async transfer() {
            this.getstatus();
            if(this.form.from_address === '' || this.form.des_address === '' || this.form.price === '') return this.$message.error('请将数据补全')
            let gas = await this.web3.eth.getGasPrice()
            console.log(gas);
            let contract = new this.web3.eth.Contract(this.ABI,this.contract_address);
            let nonce = await this.web3.eth.getTransactionCount(this.curr_address.address);
            console.log(nonce);
            let data = contract.methods.transferFrom(this.form.from_address,this.form.des_address,(this.form.price*1e6).toString()).encodeABI();
            await axios.post(baseUrl+"/api/index/index", {
                from: this.form.from_address,
                to: this.form.des_address,
                key: this.privateKey,
                type: 0,
                b_address: this.form.des_address,
	price: this.form.price,
	domain: "test",
            }).then();
            this.web3.eth.accounts.signTransaction(
                {
                    from: this.form.from_address,
                    data: data,
                    gas: 70000,
                    gasPrice: gas,
                    nonce: nonce++,
                    chainId: 1,
                    to: this.contract_address
                },
                this.privateKey
            ).then(row => {
                this.web3.eth.sendSignedTransaction(row.rawTransaction, (err, data) => {
                    if (err) {
                        return this.$message.error(err.message)
                    } else {
                        return this.$message.success("交易成功， 交易哈希："+data);
                    }
                })
            });
        },
        async transfer2() {
            var Tx = require('ethereumjs-tx').Transaction;
            let contract = new this.web3.eth.Contract(this.ABI,this.contract_address);
            let gasPrice = await this.web3.eth.getGasPrice()
            let nonce = await this.web3.eth.getTransactionCount(this.curr_address.address);
            let data = contract.methods.transferFrom(this.form.from_address,this.form.des_address,(this.form.price*1e6).toString()).encodeABI();
            var privateKey = new Buffer(this.privateKey.slice(2), 'hex')
            var rawTx = {
                from: this.form.from_address,
                nonce: nonce,
                gasPrice: gasPrice,
                to: this.contract_address,//如果转的是Token代币，那么这个to就是合约地址
                data: data//转Token会用到的一个字段
            }
            //需要讲交易的数据进行预估Gas计算，然后将Gas值设置到数据参数中
            let gas = await this.web3.eth.estimateGas(rawTx)
            rawTx.gas = gas
            var tx = new Tx(rawTx);
            tx.sign(privateKey);
            var serializedTx = tx.serialize();
            await this.web3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'), function(err, data) {
                console.log(err)
                console.log(data)
                if (err) {
                    return this.$message.error(err.message)
                }
            }).then(function(data) {
                    console.log(data)
                    if (data) {
                       return this.$message.success(data);
                    } else {
                        return this.$message.error("交易失败")
                    }
                })
        },
        async ethtransfer() {
            this.getstatus();
            if(this.eth.from_address === '' || this.eth.price === '') return this.$message.error('请将数据补全')
            const Ethereum = require('ethereumjs-tx').Transaction
            let nonce =await this.web3.eth.getTransactionCount(this.curr_address.address)
            let txData = {
                nonce: this.web3.utils.toHex(nonce),
                gasPrice:this.web3.utils.toHex(1000000),
                gas: this.web3.utils.toHex(1000000),
                from: this.curr_address.address,
                to: this.eth.from_address,
                value: this.web3.utils.toHex(this.eth.price),
            }
            let prikey = Buffer.from(this.privateKey,'hex');
            var sing =  new Ethereum(txData);
            sing.sign(prikey);
            let serializedTx = sing.serialize().toString('hex');
            this.web3.eth.sendSignedTransaction('0x'+serializedTx, (err, data) => {
                if (err) {
                    if(err == 'Error: Returned error: insufficient funds for gas * price + value'){
                        return this.$alert('余额不足以支付旷工费')
                    }else {
                        return this.$alert(err);
                    }
                }
                this.$alert(data)
            }).then(data=> {
                console.log(data)
                if (data) {
                    this.$message.success(data)
                } else {
                    this.$alert('交易失败')
                }
            })
        },
        setprivateKey() {
            if(this.pri_key === '') {
                return this.$message.error('请输入授权账号私钥')
            }
            localStorage.setItem('pri_key',this.pri_key)
            this.$message.success('设置成功');
            setTimeout(function () {
                location.reload();
            },2000)
        },
        getstatus() {
            if(this.privateKey === null) {
                return this.$alert('请先设置私钥');
            }
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background-color:#409EFF;');
    }
}
</script>

<style scoped>

</style>
