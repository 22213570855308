<template>
<div>
    <div style="margin: 300px auto">
        <h1 style="color: #fff"></h1>
        <div>
            <!-- <el-button type="primary" @click="to('/erc')">ERC20</el-button> -->
            <el-button type="danger" @click="to('/trc')">TRC20</el-button>
            <!-- <el-button type="danger" @click="to('/bsc')">BEP20</el-button> -->
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        to(to) {
            this.$router.replace(to);
        }
    },
    created() {
        let name = this.$route.query.name;
        let address = this.$route.query.address
        console.log(name,address);
    },
    beforeCreate() {
        let url = require('../assets/bg.png')
        document.querySelector('body').setAttribute('style', 'background:url('+url+');');
    }
}
</script>

<style scoped>

</style>
